import { BrandFeatureConfig, FeatureConfig } from '../../clientConfig/types';
import { Feature } from '../../types/feature';

// Determine if a feature is enabled or not for a specific audience.
export default function isFeatureEnabledForAudience(
    name: Feature,
    audience: string,
    brandFeatureConfig: BrandFeatureConfig,
): boolean {
    if (!name || !audience) {
        return false;
    }

    const features = brandFeatureConfig.features;
    const feature = features?.find((f: FeatureConfig) => f.name === name);
    const isEnabled = feature?.enabled ?? false;
    const isAllowedForAudience = feature?.jwtAudienceAllowList?.includes(audience) ?? false;

    return isEnabled && isAllowedForAudience;
}
