import { useTranslation } from 'react-i18next';

const useConfigTranslate = () => {
    const { i18n } = useTranslation();
    const language = i18n.language;

    return function <T extends Record<string, string>>(object: T) {
        return object[language] ?? object['en'];
    };
};

export default useConfigTranslate;
