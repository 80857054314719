import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useState } from 'react';
import { AmplitudeEventType } from '../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../clientConfig';
import { OPENID_PARAMS } from '../../../types/openid_enum';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { ClientConfigContext } from '../../clientConfigContext';
import { sendEventbyIdVerification, sendEventByPhoneNumber } from '../../services/amplitudeClient';
import { isRedirectUrlValid } from '../../services/validation';
import { useErrorLogging } from '../../util/errorUtils';
import getAccessToken from '../../util/getAccessToken';
import { redirectWithSoftLogout } from '../../util/sessionTimeout';
import withLogger, { ComponentLoggerProps } from '../HOC/WithLogger';
import CustomerServiceCard from './CustomerServiceCard';

function VerifyContactCustomerService({ log }: ComponentLoggerProps) {
    const searchParams = new URLSearchParams(document.location.search);
    const isMobile = searchParams.get(OPENID_PARAMS.MOBILE) === 'true';
    const clientLandingPageUrl = searchParams.get(OPENID_PARAMS.CANCEL_REDIRECT_URI);
    const [validRedirectUrl, setValidRedirectUrl] = useState(false);
    const [jwt, setJwt] = useState('');
    const { instance, accounts } = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    useErrorLogging(log);

    useEffect(() => {
        window.scrollTo(0, 0);
        validateRedirectParameter();

        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_ContactService, 'sms');
        });
    }, []);

    const handleLinkClick = () => {
        sendEventByPhoneNumber(
            jwt,
            AmplitudeEventType.IdentityVerification_ContactService_Call,
            'sms',
            formatPhoneNumber(clientConfiguration.customerSupportNumber),
        );
    };
    const validateRedirectParameter = async () => {
        let isValid = false;
        if (clientLandingPageUrl) {
            isValid = await isRedirectUrlValid(clientLandingPageUrl);
        }
        setValidRedirectUrl(isValid);
    };
    const handleCloseButtonClick = () => {
        sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_ContactService_Close, 'sms');
        redirectWithSoftLogout(instance, clientLandingPageUrl as string);
    };

    if (!isMobile && clientLandingPageUrl && validRedirectUrl) {
        return (
            <CustomerServiceCard
                closeUrl={clientLandingPageUrl}
                onClose={handleCloseButtonClick}
                onLinkClick={handleLinkClick}
                variant='closable'
            />
        );
    } else {
        // Mobile will provide a back button for the user to touch, don't display the Close button
        return <CustomerServiceCard onLinkClick={handleLinkClick} variant='default' />;
    }
}

export default withLogger(VerifyContactCustomerService, { eventSource: 'VerifyContactCustomerService' });
