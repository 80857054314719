import { useContext } from 'react';
import { VendorConfig } from '../../clientConfig/types';
import { ClientConfigContext } from '../clientConfigContext';

const useVendorConfig = (vendor: string) => {
    const { identityVerification } = useContext(ClientConfigContext);
    return identityVerification?.vendors?.find(
        (vendorFromConfig) => vendorFromConfig.vendor === vendor,
    ) as VendorConfig;
};

export default useVendorConfig;
