/**
 * Analytics events
 * @link https://praiahealth.atlassian.net/wiki/spaces/PPA/pages/34603120/SPI+-+Data+Collection
 */
export enum AmplitudeEventType {
    ActivationCode_Prerequisite_Confirm = 'SSO - EHR Authorization - Activation Code - Prerequisite - Confirm',
    ActivationCode_Prerequisite_Deny = 'SSO - SignUp - EHR Activation Code - Prerequisite - Deny',
    ActivationCode_Prerequisite_Question = 'SSO - EHR Authorization - Prerequisite - Question',
    ClearIdentityVerification_Success = 'SSO - EHR Authorization - Identity Verification - Clear Activation - Success',
    ClearIdentityVerification_Incomplete = 'SSO - EHR Authorization - Identity Verification - Incomplete',
    ClearIdentityVerification_Incomplete_Next = 'SSO - EHR Authorization - Identity Verification - Incomplete - Next',
    EHRActivationCode_CodeEntry = 'SSO - EHR Authorization - Activation Code - Code Entry',
    EHRActivationCode_CodeEntry_Consent = 'SSO - EHR Authorization - EHR Activation Code - Code Entry - Consent',
    EHRActivationCode_CodeEntry_Invalid = 'SSO - EHR Authorization - EHR Activation Code - Code Entry - Invalid',
    EHRActivationCode_CodeEntry_Next = 'SSO - EHR Activation Code - Code Entry - Next',
    EHRAuthorized = 'SSO - EHR Authorized',
    IdentityVerification_ContactService = 'SSO - EHR Authorization - Identity Verification - Contact Service',
    IdentityVerification_ContactService_Call = 'SSO - EHR Authorization - Identity Verification - Contact Service - Call',
    IdentityVerification_ContactService_Close = 'SSO - EHR Authorization - Identity Verification - Contact Service - Close',
    IdentityVerification_DemographicForm = 'SSO - EHR Authorization - Identity Verification - Demographic Form',
    IdentityVerification_DemographicForm_Back = 'SSO - EHR Authorization - Identity Verification - Demographic Form - Back', // TODO:
    IdentityVerification_DemographicForm_Consent = 'SSO - EHR Authorization - Identity Verification - Demographic Form - Consent',
    IdentityVerification_MethodSelection = 'SSO - EHR Authorization - Identity Verification - Activation Method - Options',
    IdentityVerification_MethodSelection_Select = 'SSO - EHR Authorization - Identity Verification - Activation Method - Selected',
    IdentityVerification_NumberVerified = 'SSO - EHR Authorization - Identity Verification - Number Verified',
    IdentityVerification_NumberVerify_EnterInformation = 'SSO - EHR Authorization - Identity Verification - Number Verified - Enter information', //TODO
    IdentityVerification_PhoneEntry = 'SSO - EHR Authorization - Identity Verification - Phone Entry',
    IdentityVerification_PhoneEntry_Request = 'SSO - EHR Authorization - Identity Verification - Phone Entry - Request',
    IdentityVerification_RequestNewVerificationCode = 'SSO - EHR Authorization - Identity Verification - Request New Verification Code',
    IdentityVerification_VerificationCode = 'SSO - EHR Authorization - Identity Verification - Verification Code',
    IdentityVerification_VerificationCode_Back = 'SSO - EHR Authorization - Identity Verification - Verification Code - Back',
    IdentityVerification_VerificationCode_Invalid = 'SSO - EHR Authorization - Identity Verification - Verification Code - Invalid',
    SignIn_AccountType_Failure = 'SSO - Welcome - Username & Password Screen - SignIn - Error',
    SignIn_AccountType_Success = 'SSO - Welcome - Username & Password Screen - SignIn - Success',
    SSOSignUp_AIA_ActivateAccount_Link = 'SSO - SignUp - AIA - Activate Account Link',
    SSOSignUp_AIA_NoRecordMatch_Info = 'SSO - SignUp - AIA - No Record Match - Info',
    SSOSignUp_AIA_NoRecordMatch_Info_Next = 'SSO - SignUp - AIA - No Record Match - Info - Next',
    SSOSignUp_AIA_RecordMatch_Info = 'SSO - SignUp - AIA - Record Match - Info',
    SSOSignUp_AIA_RecordMatch_Info_Next = 'SSO - SignUp - AIA - Record Match - Info - Next',
    SSOSignUp_AIA_RecordMatch_Success = 'SSO - SignUp - AIA - Record Match - Success',
    SSOSignUp_Cancel = 'SSO - SignUp - Cancel',
    SSOSignUp_Next = 'SSO - SignUp - Next',
    SSOSignUp_Screen = 'SSO - SignUp - Screen',
    SSOSignUp_Screen_AutofillEmail = 'SSO - SignUp - Screen - Autofill Email',
    SSOSignUp_Screen_AutofillEmail_Confirm = 'SSO - SignUp - Screen - Autofill Email - Confirm',
    SSOSignUp_Screen_AutofillEmail_Update = 'SSO - SignUp - Screen - Autofill Email - Update',
    UsernameRecovery_Confirm = 'SSO - Username recovery - Confirm',
    UsernameRecovery_SignIn = 'SSO - Username recovery - SignIn',
    UsernameRecoveryScreen = 'SSO - Username recovery Screen',
    UsernameRecoveryScreen_Next = 'SSO - Username recovery Screen - Next',
}
