import { List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { TextListWithTranslation } from '../../../../../../clientConfig/types';
import useConfigTranslate from '../../../../../hooks/useConfigTranslate';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
    },
    listItem: {
        display: 'list-item',
        left: 12,
        listStylePosition: 'inside',
        listStyleType: 'disc',
        marginTop: 0,
        padding: 0,
        position: 'relative',
        '&::marker': {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    listItemText: {
        margin: 0,
        display: 'inline-flex',
        whiteSpace: 'break-spaces',
        width: 'calc(100% - 28px)',
    },
    listItemTypography: {
        lineHeight: '24px',
    },
}));

type BulletPointsListProps = {
    items: TextListWithTranslation[];
};

const BulletPointsList: React.FC<BulletPointsListProps> = ({ items }) => {
    const classes = useStyles();
    const configT = useConfigTranslate();

    return (
        <List className={classes.list}>
            {items.map((item, index) => (
                <ListItem key={index} className={classes.listItem}>
                    <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary={
                            <Typography variant='subtitle1' className={classes.listItemTypography}>
                                {configT(item)}
                            </Typography>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default BulletPointsList;
