import { OidcState } from '../../types/oidcState';
import { OPENID_PARAMS } from '../../types/openid_enum';

/**
 * Get the state from the URL query parameters.
 */
export const fetchOidcState = <T extends OidcState>(): T => {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const encodedState = searchParams.get(OPENID_PARAMS.STATE) as string;
    const stringState = atob(encodedState); // Buffer.from(encodedState, "base64").toString() apparently not supported by browsers yet
    return JSON.parse(stringState) as T;
};
