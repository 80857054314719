import { useHistory } from 'react-router-dom';
import { OidcState } from '../../types/oidcState';
import { OPENID_PARAMS } from '../../types/openid_enum';
import { ApiRequestLogger } from '../logger/apiRequestLogger';
import { createErrorParams } from '../util/errorUtils';
import { fetchOidcState } from '../util/oidcStateExtractUtils';

type HistoryType = ReturnType<typeof useHistory>;

type LoggingParams = {
    log: ApiRequestLogger;
    errorPath?: string;
};

type ErrorHandlerOptions = LoggingParams & {
    message: string;
    rootCause?: string;
    history: HistoryType;
};

type UseOIDCStateOptions = LoggingParams & {
    isRequired: boolean;
    onError?: (options: ErrorHandlerOptions) => void;
};

const defaultErrorHandler = ({ message, rootCause, history, log, errorPath }: ErrorHandlerOptions) => {
    log.error(`${message}: ${rootCause}`);
    history.push({
        pathname: errorPath,
        search: '?' + createErrorParams(message),
    });
};

/**
 * Get the state from the URL query parameters.
 */
const useOidcState = <T extends OidcState>({
    isRequired,
    log,
    errorPath,
    onError = defaultErrorHandler,
}: UseOIDCStateOptions): T | undefined => {
    const history = useHistory();

    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const encodedState = searchParams.get(OPENID_PARAMS.STATE) as string;

    if (!encodedState) {
        if (isRequired) {
            onError({ message: 'No state found in the URL', history, log, errorPath });
        }
        return;
    }

    try {
        return fetchOidcState<T>();
    } catch (err: any) {
        onError({ message: 'Error parsing state', rootCause: err.message, history, log, errorPath });
    }
};

export { useOidcState };
