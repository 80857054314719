import { TextListWithTranslation } from '../../../../clientConfig/types';

export enum AnalyticsIdentityVerificationMethod {
    CLEAR = 'clear',
    ACTIVATION_CODE = 'activation_code',
    SMS = 'sms',
}
export const AnalyticsIdentityVerificationErrorMapping: Record<string, string> = {
    'Vendor is not supported': 'vendor_not_supported',
    'Out-Of-Region': 'out_of_region',
    'Session demographics are missing': 'session_demographics_missing',
};
export function toAnalyticsActivationMethodList(buttons: TextListWithTranslation[]): string {
    const buttonMapping: Record<string, AnalyticsIdentityVerificationMethod> = {
        ClearButton: AnalyticsIdentityVerificationMethod.CLEAR,
        ActivationCode: AnalyticsIdentityVerificationMethod.ACTIVATION_CODE,
        BasicActivation: AnalyticsIdentityVerificationMethod.SMS,
    };
    return buttons.map((e) => buttonMapping[e.name] ?? e.name).join(':');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapToAnalyticsClearErrorType(error: any): string {
    if (error?.cause?.message) {
        return AnalyticsIdentityVerificationErrorMapping[error?.['cause']?.message] ?? 'unknown';
    }
    return 'unknown';
}
