import { Container, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { AmplitudeEventType } from '../../../../../../analytics/amplitudeEvents';
import { TextListWithTranslation } from '../../../../../../clientConfig/types';
import { PimsPostVerificationSessionRequest } from '../../../../../../router/types';
import useConfigTranslate from '../../../../../hooks/useConfigTranslate';
import { ApiRequestLogger } from '../../../../../logger/apiRequestLogger';
import { sendEventByEventObject } from '../../../../../services/amplitudeClient';
import { useErrorHistoryPush } from '../../../../../util/errorUtils';
import withLogger from '../../../../HOC/WithLogger';
import { AuthenticatedRoutes } from '../../../../routers';
import { AnalyticsIdentityVerificationMethod } from '../../analytics';
import { ClearButton, ClearConfig } from '../../clear';
import BasicButton from './BasicButton';

type CardActionsProps = {
    buttonsList: TextListWithTranslation[];
    theme: any;
    cdnPath: string;
    clearConfig: ClearConfig;
    updateSessionCookie: (verificationSessionId: string) => void;
    postVerificationSessionParams: PimsPostVerificationSessionRequest;
    token: string | undefined;
    jwt: string;
    isCrossRegion: boolean;
    log: ApiRequestLogger;
};

const CardActions: React.FC<CardActionsProps> = ({
    buttonsList,
    theme,
    cdnPath,
    clearConfig,
    updateSessionCookie,
    postVerificationSessionParams,
    token,
    jwt,
    isCrossRegion,
    log,
}) => {
    const history = useHistory();
    const errorHistoryPush = useErrorHistoryPush();

    const redirectToCustomerService = (error: Error) => {
        errorHistoryPush(
            AuthenticatedRoutes.VerificationRoutes.ContactCustomerService,
            `IdentityVerificationPage(): Error occurred - ${error.message}`,
        );
    };

    const useDemographicsFlow = () => {
        sendEventByEventObject(jwt, {
            event_type: AmplitudeEventType.IdentityVerification_MethodSelection_Select,
            event_properties: {
                activation_method: AnalyticsIdentityVerificationMethod.SMS,
            },
        });
        history.push({
            pathname: AuthenticatedRoutes.VerificationRoutes.StartDemographicsFlow,
            state: { isCrossRegion },
        });
    };

    const useActivationCodeFlow = () => {
        sendEventByEventObject(jwt, {
            event_type: AmplitudeEventType.IdentityVerification_MethodSelection_Select,
            event_properties: {
                activation_method: AnalyticsIdentityVerificationMethod.ACTIVATION_CODE,
            },
        });
        history.push(AuthenticatedRoutes.VerificationRoutes.ActivationCodeFlow);
    };

    const configT = useConfigTranslate();

    const useStyles = makeStyles<Theme, any, string>(() => ({
        root: {
            height: '100%',
            display: 'flex',
            alignItems: 'stretch',
            width: 'max-content',
            gap: 16,
            flexDirection: 'column',
        },
    }));

    const ButtonsDiv = ({ children }: { children: React.ReactNode }) => {
        const classes = useStyles({});

        return <Container className={classes.root}>{children as React.ReactElement}</Container>;
    };

    return (
        <ButtonsDiv>
            {buttonsList.map((button, index: number) => {
                if (button.name === 'ClearButton') {
                    return (
                        <ClearButton
                            theme={theme}
                            key={index}
                            cdnPath={cdnPath}
                            config={clearConfig}
                            onError={redirectToCustomerService}
                            onSessionCreated={updateSessionCookie}
                            params={postVerificationSessionParams}
                            token={token}
                            width={240}
                            jwt={jwt}
                        >
                            {configT(button)}
                        </ClearButton>
                    );
                } else if (button.name === 'ActivationCode') {
                    return (
                        <BasicButton key={index} onClick={useActivationCodeFlow}>
                            {configT(button)}
                        </BasicButton>
                    );
                } else if (button.name === 'BasicActivation') {
                    return (
                        <BasicButton key={index} onClick={useDemographicsFlow}>
                            {configT(button)}
                        </BasicButton>
                    );
                }

                log.error(`Unknown button type: ${button.name}, button: ${JSON.stringify(button)}`);
                return null;
            })}
        </ButtonsDiv>
    );
};

export default withLogger(CardActions, {
    eventSource: 'CardActions',
});
