import { Button as MuiButton, Theme, makeStyles } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import React, { ReactNode } from 'react';
import { ButtonStyleOverrides, ButtonStylesType, buttonStyles } from '../../../assets/themes/buttonStyles';

export type ButtonProps = ButtonStyleOverrides & {
    children: ReactNode;
    disabled?: boolean | undefined;
    variant: 'outlined' | 'contained' | 'text' | 'banner';
    startIcon?: ReactNode;
    type?: 'button' | 'reset' | 'submit' | undefined;
    onClick?: any;
};

const defaults: ButtonStylesType = {
    borderWidth: 1,
    fontSize: 16,
    fontWeight: 500,
    height: 44,
};

export const Button = ({
    activeBackgroundColor,
    backgroundColor,
    children,
    disabled,
    fontFamily,
    height,
    hoverBackgroundColor,
    maxWidth,
    onClick,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    startIcon,
    textTransform,
    type,
    variant,
    width,
    overrideWidth,
}: ButtonProps) => {
    const buttonStyleProps = { ...defaults };

    const muiType = type ?? 'button';

    let muiVariant: 'outlined' | 'contained' | 'text' | undefined;

    switch (variant) {
        case 'banner':
            muiVariant = 'outlined';
            buttonStyleProps.borderWidth = 1;
            buttonStyleProps.fontSize = 14;
            buttonStyleProps.fontWeight = 500;
            buttonStyleProps.height = 39;
            buttonStyleProps.width = width ?? 80;
            buttonStyleProps.backgroundColor = (palette: PaletteOptions) => palette.common?.white || 'initial';
            break;
        case 'outlined':
            muiVariant = 'outlined';
            buttonStyleProps.borderWidth = 2;
            break;
        default:
            muiVariant = variant;
            break;
    }

    const useButtonStyles = makeStyles<Theme, ButtonStylesType>(buttonStyles);
    const classStyle = useButtonStyles({
        ...buttonStyleProps,
        ...(activeBackgroundColor !== undefined && { activeBackgroundColor }),
        ...(backgroundColor !== undefined && { backgroundColor }),
        ...(fontFamily !== undefined && { fontFamily }),
        ...(height !== undefined && { height }),
        ...(hoverBackgroundColor !== undefined && { hoverBackgroundColor }),
        ...(maxWidth !== undefined && { maxWidth }),
        ...(paddingBottom !== undefined && { paddingBottom }),
        ...(paddingLeft !== undefined && { paddingLeft }),
        ...(paddingRight !== undefined && { paddingRight }),
        ...(paddingTop !== undefined && { paddingTop }),
        ...(textTransform !== undefined && { textTransform }),
        ...(width !== undefined && { width }),
        ...(overrideWidth !== undefined && { overrideWidth }),
    });

    return (
        <MuiButton
            classes={classStyle}
            disabled={disabled}
            variant={muiVariant}
            disableElevation
            type={muiType}
            onClick={onClick}
            startIcon={startIcon}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
