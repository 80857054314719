import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useState } from 'react';
import { AmplitudeEventType } from '../../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../../clientConfig';
import { AIAState } from '../../../../types/aiaState';
import { AUTO_INSTANT_ACTIVATION_PARAMS, OPENID_PARAMS } from '../../../../types/openid_enum';
import { ClientConfigContext } from '../../../clientConfigContext';
import { useBrand } from '../../../hooks/useBrand';
import { useMyChartRedirect } from '../../../hooks/useMyChartRedirect';
import { useOidcState } from '../../../hooks/useOidcState';
import { sendEventForAIA } from '../../../services/amplitudeClient';
import { useErrorLogging } from '../../../util/errorUtils';
import getAccessToken from '../../../util/getAccessToken';
import RedirectButtonCard from '../../containers/RedirectButtonCard';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import { AuthenticatedRoutes } from '../../routers';

function AutoInstantActivationAdditionalInfo({ log }: ComponentLoggerProps): JSX.Element {
    const { instance, accounts } = useMsal();
    const [jwt, setJwt] = useState('');
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const { brand, crossRegionEnabled } = useBrand();
    const oidcState = useOidcState<AIAState>({ log, isRequired: true });
    const { getSamlSsoPage } = useMyChartRedirect();
    useErrorLogging(log);

    const searchParams = new URLSearchParams(window.location.search);
    const crossRegion = searchParams.get(OPENID_PARAMS.CROSS_REGION);
    const ehrSystem = oidcState?.ehrSystem as string;
    const redirectURI = getSamlSsoPage(ehrSystem);

    const nextUrlParams = {
        [OPENID_PARAMS.BRAND]: brand,
        [OPENID_PARAMS.CROSS_REGION]: crossRegion ?? (crossRegionEnabled ? 'true' : 'false'),
        [OPENID_PARAMS.EHR_SYSTEM]: ehrSystem,
        [OPENID_PARAMS.REDIRECT_URI]: redirectURI,
        [AUTO_INSTANT_ACTIVATION_PARAMS.REGISTER_PHONE]: 'true',
    };

    const handleNext = () => {
        sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_NoRecordMatch_Info_Next);
    };

    useEffect(() => {
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_NoRecordMatch_Info);
        });
    }, []);

    return (
        <RedirectButtonCard
            title='Provide additional information'
            content='We couldn’t find a match. Please provide additional information to help us locate your medical record.'
            url={AuthenticatedRoutes.RootRoutes.Registration}
            onNext={handleNext}
            params={nextUrlParams}
        />
    );
}

export default withLogger(AutoInstantActivationAdditionalInfo, { eventSource: 'AutoInstantActivationError' });
