import { Theme, ThemeOptions } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Styles } from '@material-ui/core/styles/withStyles';

type ColorType = string | ((palette: PaletteOptions) => string);

export type ButtonStyleOverrides = {
    activeBackgroundColor?: ColorType;
    backgroundColor?: ColorType;
    fontFamily?: string;
    height?: number;
    hoverBackgroundColor?: ColorType;
    maxWidth?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    textTransform?: 'none' | 'uppercase';
    width?: number;
    overrideWidth?: string;
};

export type ButtonStylesType = ButtonStyleOverrides & {
    borderWidth: number;
    fontSize: number;
    fontWeight: number;
    minWidth?: number;
    activeBackgroundColor?: ColorType;
    backgroundColor?: ColorType;
    hoverBackgroundColor?: ColorType;
    overrideWidth?: string;
};

type ButtonStylesThemeOptions = ThemeOptions & {
    breakpoints: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        up: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        down: any;
    };
    palette: {
        common: { black: string; white: string };
        primary: { main: string; light: string; dark: string };
        secondary: {
            main: string;
            light: string;
            dark: string;
        };
    };
    shadows: string[];
    typography: {
        body1: { fontFamily?: string };
    };
};

type ColorCallbackFunc = (options: ButtonStylesType) => string;
type ColorCallbackParams = { defaultColor: string; palette?: PaletteOptions };
type ColorOption = 'backgroundColor' | 'hoverBackgroundColor' | 'activeBackgroundColor';

const getColorCallback = (attr: ColorOption, { palette, defaultColor }: ColorCallbackParams): ColorCallbackFunc => {
    return (options: ButtonStylesType) => {
        const color: ColorType | undefined = options[attr];
        if (typeof color === 'string') {
            return color;
        } else if (color && palette) {
            return color(palette);
        } else if (defaultColor) {
            return defaultColor;
        } else {
            return 'initial';
        }
    };
};

export const buttonStyles: Styles<Theme, ButtonStylesType, string> = (theme: ButtonStylesThemeOptions) => ({
    root: {
        whiteSpace: 'nowrap',
        borderRadius: 24,
        height: (options) => options.height,
        [theme.breakpoints.down('xs')]: {
            minWidth: (options) => options.width ?? 120,
            width: (options) => options.overrideWidth ?? 'fit-content',
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: (options) => options.width ?? 144,
            width: (options) => options.overrideWidth ?? 'fit-content',
        },
        paddingBottom: (options) => options.paddingBottom ?? 0,
        paddingLeft: (options) => options.paddingLeft ?? '3em',
        paddingRight: (options) => options.paddingRight ?? '3em',
        paddingTop: (options) => options.paddingTop ?? 0,
    },
    label: {
        textTransform: (options) => options.textTransform ?? 'uppercase',
        fontFamily: (options) => options.fontFamily ?? theme.typography.body1.fontFamily,
        fontSize: (options) => options.fontSize,
        fontWeight: (options) => options.fontWeight,
        lineHeight: '19.2px',
        textWrap: 'wrap',
    },
    outlined: {
        color: theme.palette.secondary.main,
        backgroundColor: getColorCallback('backgroundColor', {
            palette: theme.palette,
            defaultColor: theme.palette.secondary.main,
        }),
        borderWidth: (options) => options.borderWidth,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: getColorCallback('hoverBackgroundColor', { defaultColor: 'initial' }), //options => options.hoverBackgroundColor ?? 'initial',
            borderColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: getColorCallback('activeBackgroundColor', { defaultColor: 'initial' }), //options => options.activeBackgroundColor ?? 'initial',
            borderColor: theme.palette.secondary.dark,
        },
        '&:disabled': {
            borderColor: theme.palette.secondary.main,
            opacity: 0.4,
        },
    },
    contained: {
        backgroundColor: getColorCallback('backgroundColor', {
            palette: theme.palette,
            defaultColor: theme.palette.primary.main,
        }),
        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        padding: '0 1em',
        boxShadow: theme.shadows[0],
        '&:hover': {
            boxShadow: theme.shadows[0],
            backgroundColor: getColorCallback('hoverBackgroundColor', { defaultColor: theme.palette.primary.light }),
        },
        '&:active': {
            boxShadow: theme.shadows[0],
            backgroundColor: getColorCallback('activeBackgroundColor', { defaultColor: theme.palette.primary.dark }),
        },
        '&:disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.4,
        },
    },
    text: {
        color: theme.palette.secondary.dark,
        '&:hover': {
            color: theme.palette.secondary.light,
            backgroundColor: 'initial',
        },
        '&:active': {
            color: theme.palette.secondary.light,
        },
    },
});
