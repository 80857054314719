import React from 'react';
import { useTranslation } from 'react-i18next';
import { buildUrlParams } from '../../util/urlUtils';
import Button from '../elements/Button';
import TypographyI18N from '../elements/TypographyI18N';
import BrandedCard, { BrandedCardActions, BrandedCardContent, BrandedCardHeader } from './BrandedCard_v2';

type UrlParams = {
    [key: string]: string | boolean;
};

type RedirectButtonCardProps = {
    title: string;
    content: string;
    url: string;
    params?: UrlParams;
    onNext: () => void;
};

/**
 * A card with one-button that navigates the user to a new page when clicked.
 */
const RedirectButtonCard = (props: RedirectButtonCardProps): JSX.Element => {
    const { title, content, url, onNext } = props;
    const { t } = useTranslation();

    let nextUrl: string;
    if (props.params) {
        const nextUrlParams = buildUrlParams(props.params);
        nextUrl = `${url}?${nextUrlParams.toString()}`;
    } else {
        nextUrl = url;
    }

    const handleNextButtonClick = () => {
        onNext();
        // TODO: history.push(nextUrl)?
        // AIA is currently using this to redirect authenticated users to Prove flow, which is probably a mistake
        window.location.replace(nextUrl);
    };

    return (
        <BrandedCard>
            <BrandedCardHeader>
                <TypographyI18N variant='h2'>{title}</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent>
                <TypographyI18N>{content}</TypographyI18N>
            </BrandedCardContent>
            <BrandedCardActions>
                <Button type='submit' variant='contained' onClick={handleNextButtonClick}>
                    {t('Next')}
                </Button>
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default RedirectButtonCard;
