/**
 * https://confluence.dig.engineering/display/OneAccount/PIP+-+Client-Side+Logout
 *
 * Explain on the usage of
 * const {login, result, error} = useMsalAuthentication(InteractionType.Silent, request);
 * const {instance, accounts, inProgress} = useMsal();
 *
 * inProgress is a variable that captures the state that msal is in.
 * When the react app loads, the inProgress jump through a number of states from
 * startup --> handleRedirect --> none ----> (if sign-in is needed, then) --> ssoSilent --> login
 *
 * The startup phase is no-op. HandleRedirect phase will drive the OAuth authorization code process when the IDP redirects
 * back to PIP. "none" is the rest state, but it is not the final step when the app is unauthed. It will trigger ssoSilent
 * because of useMsalAuthentication(InteractionType.Silent, request).
 *
 * The ssoSilent phase attempts to authenticate via SSO sign-in. On error, the useEffect kicks it and then login(InteractionType.Redirect, request) is ran.
 * This invokes the login phase where it will redirect to the IDP.
 *
 * The startup phase is a good time to check for the internal auth state, which is compromised of
 * three variables:
 *     accounts     - useMsal returns the current user accounts for this app. This is stored in localStorage.
 *     authResult   - useMsalAuthentication returns this only when it performs a successful login. This means that the request
 *                    would have come back from the IDP
 *     actTimestamp - the last user activity timestamp. This is kept in the sessionStorage. If it does not exist,
 *                    then the user visited using a new session (new tab).
 *
 * When the current user account is present, but both authResult and timestamp is undefined, this means that the
 * React app was launch in a new tab without going to the IDP. Stale login is highly probable (since local storage does not get
 * cleared when the browser gets closed).
 * Hence, we must perform a soft logout and force the patient to SSO signin.
 * When the current user account and timestamp are present, this means the user is on an active session,
 * perhaps navigating using the url or clicking the back button. No operation necessary.
 */
import { AccountInfo, AuthenticationResult, InteractionStatus, IPublicClientApplication } from '@azure/msal-browser';
import { OPENID_PARAMS } from '../../types/openid_enum';
import { ApiRequestLogger } from '../logger/apiRequestLogger';
import { isRedirectUrlValid } from '../services/validation';

export const PIP_USER_ACTIVITY_KEY = 'pip_user_activity';

/**
 * Sets the user activity timestamp to the current epoch time.
 */
function refreshUserActivityTimestamp(): void {
    const currentTime = new Date().getTime();
    sessionStorage.setItem(PIP_USER_ACTIVITY_KEY, `${currentTime}`);
}

/**
 * User is considered active if the time duration from the last user activity timestamp is less than the allowed duration (in ms).
 * If the timestamp does not exist, then it should be considered expired.
 * @param userActivityLimitMs the limit that the user gets to idle on PIP
 */
function isUserActive(userActivityLimitMs = 900000): boolean {
    const lastUserTimestamp = sessionStorage.getItem(PIP_USER_ACTIVITY_KEY) || '0';
    const timeDiff = new Date().getTime() - Number.parseInt(lastUserTimestamp);
    const isUserActive = timeDiff < userActivityLimitMs; // in milliseconds
    return isUserActive;
}

/**
 * Adds event listeners to the browser window. Refreshes the user-activity on any clicks and key inputs.
 */
function initializeTimerRefreshListeners(): void {
    const events = ['click', 'keyup'];
    events.forEach((event) => {
        window.addEventListener(event, refreshUserActivityTimestamp);
    });
}

/**
 * Maintains a global idleness timer instance for the React app. Every ten seconds, it will check if the user is active
 * and force a logout when the user is not active.
 */
let idlenessTimer: NodeJS.Timeout;
export function initializeSessionTimeout(instance: IPublicClientApplication, userActivityDurationMs: number): void {
    if (!idlenessTimer) {
        initializeTimerRefreshListeners();
        idlenessTimer = setInterval(() => {
            if (!isUserActive(userActivityDurationMs)) {
                // TODO: Phase 2 is to pop-up a dialog to the user to warn them that the session is about to logout.
                instance.logoutRedirect({
                    // Hard logout will logout PIP and the IDP
                    postLogoutRedirectUri: window.location.href,
                }); // need to preserve all the query parameters that were sent through.
            }
        }, 10000); // run every 10secs
    }
}

/**
 * Since the msal session is stored in the browser's local session, the accounts data can be stale on a new session.
 * Thus, it is possible that previous user's session is still lingering.
 *
 * The function performs a soft logout under certain conditions and then updates the user activity timestamp
 *
 * @param instance msal
 * @param accounts the account info
 * @param authResult any result from a loginRedirect
 * @param inProgress the msal state
 * @param userActivityLimitMs the limit that the user gets to idle on PIP
 */
export function manageAuthOnStateChange(
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
    authResult: AuthenticationResult | null,
    inProgress: InteractionStatus,
    userActivityLimitMs: number,
): void {
    const hasAccount = accounts.length > 0;
    const userActive = isUserActive(userActivityLimitMs);
    // When account is present, but both authResult and timestamp is expired or undefined, we must do a soft logout to check back with the IDP
    if (inProgress === InteractionStatus.Startup && hasAccount && !authResult && !userActive) {
        instance.logoutRedirect({
            // soft logout will logout PIP but not the IDP
            onRedirectNavigate: () => false,
        });
    }

    if (
        (inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.SsoSilent) &&
        (hasAccount || authResult)
    ) {
        refreshUserActivityTimestamp();
    }
}

/**
 * Returns true if we can determine the user-agent is not a mobile device.
 * It currently looks at query params and does not apply window.navigator.userAgent to determine if it is mobile or not.
 */
export function isNotMobile(searchParams: URLSearchParams): boolean {
    const isMobile =
        searchParams.get(OPENID_PARAMS.MOBILE) === 'true' || searchParams.get(OPENID_PARAMS.DEVICE_TYPE) === 'mobile';
    return !isMobile;
}

export function redirectWithSoftLogout(
    instance: IPublicClientApplication,
    redirectUrl: string,
    successCallback?: () => unknown,
): void {
    const logger: ApiRequestLogger = new ApiRequestLogger();

    instance
        .logoutRedirect({
            onRedirectNavigate: () => false,
        })
        .finally(() => {
            isRedirectUrlValid(redirectUrl)
                .then((isValid) => {
                    if (isValid) {
                        if (!successCallback) {
                            window.location.replace(redirectUrl);
                            return;
                        }
                        const result = successCallback();
                        if (result instanceof Promise) {
                            result.finally(() => {
                                window.location.replace(redirectUrl);
                            });
                        } else {
                            window.location.replace(redirectUrl);
                        }
                    } else {
                        logger.warn(`redirectWithSoftLogout(): Redirect url: ${redirectUrl} is not valid `);
                    }
                })
                .catch((err) => {
                    console.log(encodeURI(err));
                });
        });
}
