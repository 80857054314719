import { useMsal } from '@azure/msal-react';
import { makeStyles, Typography } from '@material-ui/core';
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVerificationSessionCookieName } from '..';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../../../clientConfig';
import { BrandFeatureConfig } from '../../../../../clientConfig/types';
import { PimsPostVerificationSessionRequest, SessionStatus } from '../../../../../router/types';
import { OPENID_PARAMS } from '../../../../../types/openid_enum';
import { ClientConfigContext } from '../../../../clientConfigContext';
import { useBrand } from '../../../../hooks/useBrand';
import useConfigTranslate from '../../../../hooks/useConfigTranslate';
import useCookie, { setCookie } from '../../../../hooks/useCookie';
import useVendorConfig from '../../../../hooks/useVendorConfig';
import { sendEventByEventObject } from '../../../../services/amplitudeClient';
import { getIdentityVerificationSession } from '../../../../services/pimsClient';
import { getDeviceCategory } from '../../../../util/clientContextUtils';
import { useErrorHistoryPush } from '../../../../util/errorUtils';
import getAccessToken from '../../../../util/getAccessToken';
import BrandedCard, { BrandedCardActions, BrandedCardContent } from '../../../containers/BrandedCard_v2';
import withLogger, { ComponentLoggerProps } from '../../../HOC/WithLogger';
import { AuthenticatedRoutes } from '../../../routers';
import { VENDOR_NAME as CLEAR_VENDOR_NAME, VENDOR_NAME } from '../clear';
import BulletPointsList from './components/BulletPointsList';
import CardActions from './components/CardActions';
import { toAnalyticsActivationMethodList } from '../analytics';

type IdentityVerificationPageProps = ComponentLoggerProps & {
    isCrossRegion?: boolean;
    ehrSystem?: string;
};

enum PageState {
    Loading,
    NewSession,
    ExistingSession,
    Redirecting,
}

const useStyles = makeStyles(() => ({
    header: {
        marginBottom: 20,
    },
}));

/**
 * The Identity Verification Page.
 * Basic identities will arrive at this page when they need an EHR authorization.
 * @param isCrossRegion
 * @param log APIRequestLogger
 */
export const IdentityVerificationPage: React.FC<IdentityVerificationPageProps> = ({
    isCrossRegion = false,
    ehrSystem = '',
    log,
}: IdentityVerificationPageProps) => {
    const [jwt, setJwt] = useState('');
    const { instance, accounts } = useMsal();
    const { b2cTenantConfig, cdnPath, pimsApiUrl }: ClientConfigType = useContext(ClientConfigContext);
    const [token, setToken] = useState<string | undefined>(undefined);
    const { brand, identityVerification: brandIdentityVerification } = useBrand();

    if (!brandIdentityVerification) {
        log.error('No brand identity verification config found');
        return null;
    }

    const searchParams = new URLSearchParams(window.location.search);

    const errorHistoryPush = useErrorHistoryPush();

    // Get the redirect URI from the query params
    const redirectUriParam = searchParams.get(OPENID_PARAMS.REDIRECT_URI);
    const redirectUrlParam = searchParams.get(OPENID_PARAMS.REDIRECT_URL);
    const redirectUri = redirectUriParam || redirectUrlParam;

    if (!redirectUri) {
        errorHistoryPush(AuthenticatedRoutes.VerificationRoutes.ContactCustomerService, 'Missing redirect URI');
        return null;
    }

    const azureUserId = accounts?.[0]?.localAccountId || '';
    const verificationSessionCookieName = getVerificationSessionCookieName(VENDOR_NAME, azureUserId); // In the future, this could loop over multiple vendors if needed
    const [verificationSessionId] = useCookie(verificationSessionCookieName);
    const [pageState, setPageState] = useState<PageState>(
        verificationSessionId ? PageState.Loading : PageState.NewSession,
    );

    const checkForExistingSession = async () => {
        const jwt = await getAccessToken(instance, accounts, b2cTenantConfig.scopes, log);
        setJwt(jwt);

        sendEventByEventObject(jwt, {
            event_type: AmplitudeEventType.IdentityVerification_MethodSelection,
            event_properties: {
                activation_method_shown: toAnalyticsActivationMethodList(brandIdentityVerification.components.buttons),
                entry_flow: accounts[0]?.idTokenClaims?.newUser ? 'New Account creation' : 'Existing Account',
            },
        });

        // Update the page state based on the clear session cookie
        if (verificationSessionId) {
            const response = await getIdentityVerificationSession(jwt, pimsApiUrl, VENDOR_NAME, verificationSessionId);

            if (response.status === SessionStatus.IN_PROGRESS && response.token) {
                setPageState(PageState.ExistingSession);
                setToken(response.token);
            } else if (response.status === SessionStatus.COMPLETED && response.redirectUrl) {
                // A user with an already completed and successful verification session should proceed to the verification redirect page
                setPageState(PageState.Loading);
                window.location.replace(response.redirectUrl);
            } else {
                setPageState(PageState.NewSession);
            }
        }
    };

    useEffect(() => {
        checkForExistingSession();
    }, [verificationSessionId]);

    const updateSessionCookie = (verificationSessionId: string) => {
        setCookie(verificationSessionCookieName, verificationSessionId);
    };

    const { options: clearConfig } = useVendorConfig(CLEAR_VENDOR_NAME);

    const { components: componentsProps, theme }: BrandFeatureConfig = brandIdentityVerification;

    const { i18n } = useTranslation();
    const classes = useStyles();

    const postVerificationSessionParams: PimsPostVerificationSessionRequest = {
        jwt,
        pimsApiUrl,
        brand,
        ehrSystem,
        deviceType: getDeviceCategory(),
        redirectUri,
        vendor: CLEAR_VENDOR_NAME,
        language: i18n.language,
    };

    const configT = useConfigTranslate();
    const isBulletPointsList = componentsProps.list?.length > 0;

    return (
        <BrandedCard
            maxWidth={452}
            minHeight={isBulletPointsList ? 530 : 450}
            isLoading={pageState === PageState.Loading}
        >
            <BrandedCardContent
                marginTop={48}
                paddingLeft={isBulletPointsList ? 48 : 72}
                paddingRight={isBulletPointsList ? 48 : 72}
                height={isBulletPointsList ? 286 : 200}
                verticallyCentered={isBulletPointsList ? false : true}
            >
                <Typography
                    variant={isBulletPointsList ? 'subtitle1' : 'h2'}
                    className={classes.header}
                    align={isBulletPointsList ? 'justify' : 'center'}
                >
                    {configT(componentsProps.header)}
                </Typography>
                {isBulletPointsList ? <BulletPointsList items={componentsProps.list} /> : null}
            </BrandedCardContent>
            <BrandedCardActions direction='column' gap={16} height={214} justifyContent='flex-start'>
                <CardActions
                    buttonsList={componentsProps.buttons}
                    theme={theme}
                    cdnPath={cdnPath}
                    clearConfig={clearConfig}
                    updateSessionCookie={updateSessionCookie}
                    postVerificationSessionParams={postVerificationSessionParams}
                    token={token}
                    jwt={jwt}
                    isCrossRegion={isCrossRegion}
                    log={log}
                />
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default withLogger(IdentityVerificationPage, {
    eventSource: 'IdentityVerificationPage',
});
