import { useMsal } from '@azure/msal-react';
import { makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientConfigType } from '../../../clientConfig';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { ClientConfigContext } from '../../clientConfigContext';
import { redirectWithSoftLogout } from '../../util/sessionTimeout';
import Button from '../elements/Button';
import TypographyI18N from '../elements/TypographyI18N';
import BrandedCard, { BrandedCardActions, BrandedCardContent, BrandedCardHeader } from './BrandedCard_v2';

type ClosableCustomerServiceCardProps = {
    closeUrl?: string;
    onClose?: () => void;
    onLinkClick?: () => void;
    variant?: 'closable';
};

type DefaultCustomerServiceCardProps = {
    onLinkClick?: () => void;
    variant?: 'default';
};

type CustomerServiceCardProps = ClosableCustomerServiceCardProps | DefaultCustomerServiceCardProps;

const useStyles = makeStyles(() => ({
    text: {
        display: 'inline',
    },
    link: {
        textDecoration: 'none',
    },
}));

const defaultProps: DefaultCustomerServiceCardProps = {
    variant: 'default',
};

const CustomerServiceCard = (props: CustomerServiceCardProps = defaultProps): JSX.Element => {
    const { variant } = props;
    const { customerSupportNumber } = useContext<ClientConfigType>(ClientConfigContext);
    const { t } = useTranslation();
    const { instance } = useMsal();
    const classes = useStyles();

    const handleClose = () => {
        if (variant === 'closable') {
            if (props.onClose) {
                props.onClose();
            }

            if (props.closeUrl) {
                redirectWithSoftLogout(instance, props.closeUrl);
            } else {
                instance.logoutRedirect();
            }
        }
    };

    const handleLinkClick = () => {
        if (props.onLinkClick) {
            props.onLinkClick();
        }
    };

    // Mobile will provide a back button for the user to touch, don't display the Close button
    const showCloseButton = props.variant === 'closable';

    return (
        <BrandedCard>
            <BrandedCardHeader>
                <TypographyI18N variant='h2'>Contact customer service</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent paddingTop={40}>
                <Typography className={classes.text} variant='body1'>
                    {t(
                        'We could not verify your identity. To access your medical records, call customer service toll-free at',
                    )}
                </Typography>
                &nbsp;
                {
                    <a href={'tel:' + customerSupportNumber} onClick={handleLinkClick} className={classes.link}>
                        {formatPhoneNumber(customerSupportNumber)}
                    </a>
                }
                <Typography className={classes.text} variant='body1'>
                    .
                </Typography>
            </BrandedCardContent>
            <BrandedCardActions>
                {showCloseButton && (
                    <Button type='button' variant='contained' onClick={handleClose}>
                        {t('Close')}
                    </Button>
                )}
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default CustomerServiceCard;
