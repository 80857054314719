import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles<Theme, BrandedCardContentStyles, string>(() => ({
    root: {
        alignItems: 'center',
        display: (options) => (options.verticallyCentered ? 'flex' : 'inherit'),
        minHeight: (options) => options.height,
        justifyContent: 'center',
        marginTop: (options) => options.marginTop,
        paddingLeft: (options) => options.paddingLeft,
        paddingRight: (options) => options.paddingRight,
        paddingTop: (options) => options.paddingTop,
    },
}));

type BrandedCardContentStyles = {
    height: number;
    marginTop: number | string;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    verticallyCentered: boolean;
};

export type BrandedCardContentProps = {
    children: ReactNode | ReactNode[];
    height?: number;
    marginTop?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    verticallyCentered?: boolean;
};

export const defaultProps = {
    height: 286,
    marginTop: 'inherit',
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 0,
    paddingBottom: 120,
    verticallyCentered: false,
};

const BrandedCardContent: React.FC<BrandedCardContentProps> = (props: BrandedCardContentProps) => {
    const classes = useStyles({
        height: props.height ?? defaultProps.height,
        marginTop: props.marginTop ?? defaultProps.marginTop,
        paddingLeft: props.paddingLeft ?? defaultProps.paddingLeft,
        paddingRight: props.paddingRight ?? defaultProps.paddingRight,
        paddingTop: props.paddingTop ?? defaultProps.paddingTop,
        verticallyCentered: props.verticallyCentered ?? defaultProps.verticallyCentered,
    });
    const { children } = props;

    return (
        <Grid item className={classes.root} xs={12}>
            {children}
        </Grid>
    );
};
BrandedCardContent.displayName = 'BrandedCardContent';
export default BrandedCardContent;
