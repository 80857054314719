import React from 'react';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';
import { PimsPostVerificationSessionRequest } from '../../../../../router/types';
import { sendEventByEventObject } from '../../../../services/amplitudeClient';
import { postIdentityVerificationSession } from '../../../../services/pimsClient';
import Button from '../../../elements/Button';
import { AnalyticsIdentityVerificationMethod } from '../analytics';
import BasicButton from '../identityVerificationPage/components/BasicButton';
import { ClearIcon } from './ClearIcon';
import { ClearConfig } from './types';

type ClearButtonProps = {
    key: number;
    cdnPath: string;
    theme: string;
    config: ClearConfig;
    width: number;
    params: PimsPostVerificationSessionRequest;
    onError: (error: Error) => void;
    onSessionCreated: (verificationSessionId: string) => void;
    onClick?: () => void;
    token?: string;
    jwt: string;
};

// CLEAR styling UX requirements. See: https://docs.clearme.com/docs/ux-guidelines
const getTheme = (isDarkTheme: boolean) => ({
    backgroundColor: isDarkTheme ? '#041A55' : '#ffffff',
    hoverBackgroundColor: '#394168',
    fontFamily: 'Inter',
});

const getLogoIconUri = (isDarkTheme: boolean, cdnPath: string, config: ClearConfig): string => {
    return `${cdnPath}${isDarkTheme ? config.whiteLogoIconUri : config.blackLogoIconUri}`;
};

/**
 * A button to navigate to the CLEAR Hosted UI for identity verification.
 * @param config - The configuration for the CLEAR vendor.
 * @param width - The width of the button (in pixels).
 * @param token - A CLEAR session token. If not provided, we will create a new session first, before navigating to the CLEAR Hosted UI.
 * @param newSession - A boolean indicating whether to start a new session or continue an existing session with CLEAR.
 * @param params - The parameters required to create a new CLEAR verification session.
 * @param onError - A callback function to execute when an error occurs.
 * @param onSessionCreated - A callback function to execute when a new session is created.
 */
export const ClearButton: React.FC<ClearButtonProps> = ({
    key,
    theme,
    cdnPath,
    config,
    token,
    width,
    params,
    onError,
    onSessionCreated,
    jwt,
}: ClearButtonProps) => {
    const text = token ? 'Continue with CLEAR' : 'Verify with CLEAR';

    const useCreateClearVerificationSessionFlow = async (): Promise<string> => {
        try {
            const response = await postIdentityVerificationSession(params);

            if (!response || !response.sessionTokenId || !response.verificationSessionId) {
                throw new Error(`Failed to create verification session: ${JSON.stringify(response)}`);
            }

            onSessionCreated(response.verificationSessionId);
            return response.sessionTokenId;
        } catch (error) {
            onError(error as Error);
            return '';
        }
    };

    /**
     * If no token exists already, create a new session and use the new token.
     * If session creation fails, no-op. The parent component should handle the error via the onError callback.
     */
    const handleClick = async () => {
        sendEventByEventObject(jwt, {
            event_type: AmplitudeEventType.IdentityVerification_MethodSelection_Select,
            event_properties: {
                activation_method: AnalyticsIdentityVerificationMethod.CLEAR,
            },
        });
        if (!token) {
            token = await useCreateClearVerificationSessionFlow();
        }
        return window.location.replace(`${config.verifyUrl}?token=${token}`);
    };

    const isDarkTheme = theme === 'blue';
    const logoIconUri = getLogoIconUri(isDarkTheme, cdnPath, config);
    const chosenTheme = getTheme(isDarkTheme);

    return isDarkTheme ? (
        <Button
            key={key}
            variant='contained'
            activeBackgroundColor={chosenTheme.hoverBackgroundColor}
            backgroundColor={chosenTheme.backgroundColor}
            fontFamily={chosenTheme.fontFamily}
            hoverBackgroundColor={chosenTheme.hoverBackgroundColor}
            paddingLeft={0}
            paddingRight={0}
            startIcon={<ClearIcon logoIconUri={logoIconUri} />}
            textTransform='none'
            width={width}
            onClick={handleClick}
            overrideWidth={'auto'}
        >
            {text}
        </Button>
    ) : (
        <BasicButton key={key} onClick={handleClick} icon={<ClearIcon logoIconUri={logoIconUri} />}>
            {text}
        </BasicButton>
    );
};
