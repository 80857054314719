import { createContext } from 'react';
import { type ClientConfigType } from '../clientConfig/types';

export const ClientConfigContext = createContext<ClientConfigType>({
    bypassProve: false,
    customer: '',
    customerSupportNumber: '',
    brandConfig: {
        brand: '',
        crossRegionEnabled: false,
        cssbranding: '',
        links: {
            faq: '',
            privacyPolicy: '',
            termsAndConditions: '',
            nonDiscrimination: '',
        },
        logoAltText: '',
        logoSize: 0,
        logoUri: '',
        identityVerification: {
            theme: '',
            features: [],
            components: {
                header: { en: '', es: '' },
                list: [],
                buttons: [],
            },
        },
    },
    i18nPath: '',
    identityVerification: {
        vendors: [],
    },
    pimsApiUrl: '',
    myChartConfig: {
        autoInstantActivationMapping: {},
        mychartPrefix: '',
        mychartDomains: [],
    },
    myChartRedirectBaseUrls: [],
    tier: '',
    b2cTenantConfig: {
        tenantName: '',
        tenantId: '',
        signInPolicies: [],
        clientId: '',
        scopes: [],
        redirectUri: '',
        postLogoutRedirectUri: '',
    },
    cdnPath: '',
    userActivityLimitMs: 900000,
    mfaConfig: {
        checkMarkIconUri: '',
        securityIconUri: '',
        showResendButtonInMilliSecond: 1000,
        verificationCodeExpireTimeInSeconds: 1,
        mfaEnabled: true,
    },
    amplitudeConfig: {
        authUrl: '',
        noAuthUrl: '',
        enableEvents: true,
    },
    appPromotion: {
        brands: [],
        devices: [],
        enable: false,
        mode: 'banner',
    },
    version: '',
});
