import React from 'react';
import Button from '../../../../elements/Button';

type BasicButtonProps = {
    key: number;
    onClick: () => void;
    children: React.ReactNode;
    icon?: React.ReactNode;
};

const BasicButton: React.FC<BasicButtonProps> = ({ key, onClick, icon, children }) => {
    return (
        <Button
            key={key}
            backgroundColor={'initial'}
            variant='outlined'
            maxWidth={340}
            width={240}
            paddingLeft={24}
            paddingRight={24}
            startIcon={icon}
            onClick={onClick}
            overrideWidth={'auto'}
        >
            {children}
        </Button>
    );
};

export default BasicButton;
