import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    iconRoot: {},
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit',
    },
});

type ClearIconProps = {
    logoIconUri: string;
};

/**
 * The CLEAR logo icon (for use in the CLEAR button).
 * @param logoIconUri - The URL of the CLEAR logo
 */
export const ClearIcon: React.FC<ClearIconProps> = ({ logoIconUri }: ClearIconProps) => {
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} src={logoIconUri} />
        </Icon>
    );
};
