const isSdkVersionValid = (version: string, requiredVersion: string): boolean => {
    if (!version || !requiredVersion) {
        return false;
    }

    const [major, minor, patch] = version.split('.').map(Number);
    const [requiredMajor, requiredMinor, requiredPatch] = requiredVersion.split('.').map(Number);

    if (isNaN(major) || isNaN(patch) || isNaN(minor)) {
        return false;
    }

    if (major > requiredMajor) {
        return true;
    }

    if (major === requiredMajor && minor > requiredMinor) {
        return true;
    }

    if (major === requiredMajor && minor === requiredMinor && patch >= requiredPatch) {
        return true;
    }

    return false;
};

export default isSdkVersionValid;
